import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import background from "./background-hero.svg";
import { app, media } from "../../theme/media";
import { colors } from "../../theme";

const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 75vh;
  padding: 4rem 1.5rem;
  background: white;

  ${media.tablet`
    min-height: 50vh;
    padding: 4rem 2rem;
  `}

  ${media.desktop`
    min-height: 50vh;
    padding: 6rem 2rem;
  `}

  ${props =>
    props.hero &&
    css`
      position: relative;
      min-height: 80vh;
      align-items: flex-start;
      padding: 1rem 1.5rem;
      background: ${`url(${background})`};
      background-size: cover;
      background-position: left bottom;
      background-repeat: no-repeat;

      ${media.tablet`
        padding: 2rem 1.5rem;
        min-height: 80vh;
      `}

      ${media.desktop`
        padding: 3rem 1.5rem;
        min-height: 80vh;
      `}

      h1 {
        color: ${props.theme.color.text.on_dark};
      }
      p {
        color: ${props.theme.color.text.on_primary};
      }
    `}

  ${props =>
    props.primary &&
    css`
      background: ${props.theme.color.background.primary};
    `}

  ${props =>
    props.neutral &&
    css`
      background: ${props.theme.color.background.neutral_lightest};
    `}

  ${props =>
    props.transparent &&
    css`
      background: none;
    `}

  ${props =>
    props.narrative &&
    css`
      background: ${colors.narratives[props.narrative]};

      h1,
      h2,
      h3 {
        color: ${props.theme.color.text.on_dark};
      }

      p {
        color: ${props.theme.color.text.on_dark};
      }
    `}

  ${props =>
    props.dark &&
    css`
      background: ${props.theme.color.background.neutral_dark};

      h1,
      h2,
      h3,
      li,
      a {
        color: ${props.theme.color.text.on_dark};
        background: ${props.theme.color.background.neutral_dark};
      }
    `}


  ${props =>
    props.minimal &&
    css`
      min-height: 96px;
      padding: 1rem 1.5rem;

      ${media.tablet`
        min-height: 96px;
        padding: 2rem 1.5rem;
      `}

      ${media.desktop`
        min-height: 96px;
        padding: 3rem 1.5rem;
      `}
    `}


`;

const InnerContent = styled.div`
  width: 100%;
  max-width: ${app.maxWidth}px;
`;

const Section = ({
  className,
  children,
  hero,
  minimal,
  primary,
  neutral,
  dark,
  transparent,
  narrative,
}) => {
  return (
    <Content
      className={className}
      hero={hero}
      minimal={minimal}
      primary={primary}
      neutral={neutral}
      dark={dark}
      transparent={transparent}
      narrative={narrative}
    >
      <InnerContent>{children}</InnerContent>
    </Content>
  );
};

Section.defaultProps = {
  className: null,
  hero: false,
  primary: false,
  neutral: false,
  minimal: false,
  dark: false,
  transparent: false,
  narrative: null,
};

Section.propTypes = {
  /** For extending styles */
  className: PropTypes.string,
  hero: PropTypes.bool,
  primary: PropTypes.bool,
  neutral: PropTypes.bool,
  minimal: PropTypes.bool,
  dark: PropTypes.bool,
  transparent: PropTypes.bool,
  narrative: PropTypes.string,
};

export default styled(React.memo(Section))``;
