import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import ThemeProvider from "../../theme";
import GlobalStyles from "../../theme/global";
import Footer from "./Footer";
import Section from "./Section";

const Layout = ({ title, children }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title ? `${title} - ` : ""}Should I Trust Media</title>
        <meta
          name="description"
          content="Media is a very complicated web of relations and agendas. This website
          was built to gather and connect the publicly available data regarding
          the media. Our vision is to put information together in hopes that it
          will be used for investigative and educative purposes."
        />
        <script src="/plausible.js" type="text/javascript" />
      </Helmet>
      <GlobalStyles />
      <ThemeProvider>
        <>
          {children}
          <Section transparent>
            <Footer />
          </Section>
        </>
      </ThemeProvider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
