import React from "react";
import { css } from "styled-components";
import Responsive from "react-responsive";

export const sizes = {
  giant: 1070,
  desktop: 992,
  tablet: 768,
  bigPhone: 410,
  phone: 376,
};

export const app = {
  maxWidth: 920,
};

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const mediaMax = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const Desktop = props => (
  <Responsive {...props} minWidth={sizes.desktop} />
);

export const Tablet = props => (
  <Responsive {...props} minWidth={sizes.phone} maxWidth={sizes.desktop - 1} />
);

export const Mobile = props => (
  <Responsive {...props} maxWidth={sizes.tablet - 1} />
);

export const MobileAndTablet = props => (
  <Responsive {...props} maxWidth={sizes.desktop - 1} />
);

export const TabletAndDesktop = props => (
  <Responsive {...props} minWidth={sizes.tablet} />
);
