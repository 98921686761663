import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { lighten } from "polished";

export const colors = {
  neutral: {
    shade100: "#ffffff",
    shade200: "#F3F6F5",
    shade300: "#D5DCDB",
    shade400: "#A4B0AF",
    shade500: "#6A7877",
    shade600: "#3B4947",
    shade700: "#212D2C",
    shade800: "#172422",
    shade900: "#000000",
  },
  primary: {
    shade100: "#F6FCFC",
    shade200: "#E7F6F4",
    shade300: "#C9E5E2",
    shade400: "#95CAC4",
    shade500: "#2CA698",
    shade600: "#008373",
    shade700: "#00675B",
    shade800: "#00564C",
    shade900: "#004F46",
  },
  secondary: {
    shade100: "#FAF8F5",
    shade200: "#F7F4ED",
    shade300: "#F5EDDA",
    shade400: "#F2DBA5",
    shade500: "#FAC548",
    shade600: "#E8AE27",
    shade700: "#B37C15",
    shade800: "#6E4909",
    shade900: "#362103",
  },
  narratives: {
    center: "#DC5555",
    right: "#E68A44",
    pro_trump: "#0D7DD0",
    left: "#69C0C6",
    non_partisan: "#68A46D",
    pro_russia: "#6A62CB",
    local_criticism: "#B15B97",
    shadow_government: "#272CA7",
    anti_establishment: "#423862",
  },
  warning: {
    shade500: "#DE6262",
  },
  single: {
    yellow: "#F9F871",
    blue: "#00d1f2",
  },
};

const space = {
  xs: "0.25rem",
  s: "0.5rem",
  m: "1rem",
  l: "1.5rem",
  xl: "2rem",
  xxl: "3rem",
};

const zIndex = {
  level0: "-10",
  level1: "0",
  level2: "10",
  level3: "20",
  level4: "30",
  level5: "40",
  level6: "50",
};

export const theme = {
  color: {
    icon: {
      on_primary: colors.neutral.shade100,
      on_light: colors.neutral.shade700,
      muted: colors.neutral.shade500,
      primary: colors.primary.shade500,
      secondary: colors.secondary.shade500,
      secondary_light: colors.secondary.shade200,
      secondary_dark: colors.secondary.shade600,
      secondary_fill: "white",
      warning: colors.warning.shade500,
      yellow: colors.single.yellow,
      yellow_light: lighten(0.22, colors.single.yellow),
      blue: colors.single.blue,
      blue_light: lighten(0.42, colors.single.blue),
    },
    background: {
      white: colors.neutral.shade100,
      neutral_lightest: colors.neutral.shade200,
      neutral_light: colors.neutral.shade300,
      neutral: colors.neutral.shade500,
      neutral_dark: colors.neutral.shade700,
      neutral_darkest: colors.neutral.shade900,
      primary_lightest: colors.primary.shade100,
      primary_light: colors.primary.shade200,
      primary: colors.primary.shade500,
      primary_dark: colors.primary.shade600,
      secondary_lightest: colors.secondary.shade100,
      secondary_light: colors.secondary.shade200,
      secondary: colors.secondary.shade500,
      secondary_dark: colors.secondary.shade700,
    },
    border: {
      white: colors.neutral.shade100,
      neutral_lightest: colors.neutral.shade300,
      neutral_light: colors.neutral.shade400,
      neutral: colors.neutral.shade600,
      neutral_dark: colors.neutral.shade700,
      primary: colors.primary.shade500,
      primary_light: colors.primary.shade300,
      secondary: colors.secondary.shade500,
      secondary_light: colors.secondary.shade300,
      secondary_dark: colors.secondary.shade600,
    },
    shadow: {
      neutral_light: colors.neutral.shade300,
      neutral: colors.neutral.shade500,
      neutral_dark: colors.neutral.shade700,
      primary_light: colors.primary.shade200,
      secondary_light: colors.secondary.shade200,
    },
    text: {
      on_light: colors.neutral.shade700,
      on_dark: colors.neutral.shade100,
      on_primary: colors.primary.shade200,
      on_secondary: colors.neutral.shade100,
    },
    text_muted: {
      on_light: colors.neutral.shade400,
      on_dark: colors.neutral.shade400,
      on_primary: colors.neutral.shade300,
      on_secondary: colors.neutral.shade200,
    },
    text_primary: {
      on_light: colors.primary.shade500,
      on_dark: colors.primary.shade200,
      on_primary: colors.primary.shade100,
    },
    text_secondary: {
      on_light: colors.secondary.shade500,
      on_dark: colors.secondary.shade200,
      on_secondary: colors.secondary.shade100,
    },
    text_warning: {
      on_light: colors.warning.shade500,
    },
    link: {
      on_light: colors.primary.shade500,
      on_primary: colors.neutral.shade100,
      on_secondary: colors.neutral.shade100,
    },
  },
  shape: {
    border_radius: {
      square: 0,
      little: "4px",
      rounded: "16px",
      round: "9999px",
    },
  },
  space: {
    single: {
      xs: space.xs,
      s: space.s,
      m: space.m,
      l: space.l,
      xl: space.xl,
      xxl: space.xxl,
    },
    gap: {
      element_small: space.s,
      element: space.m,
      component: space.l,
      layout: space.xl,
      mobile_layout: space.m,
    },
    square: {
      element_small: space.s,
      element: space.m,
      component: space.l,
      layout: space.xl,
      mobile_layout: space.m,
    },
    squish: {
      element_small: `${space.xs} ${space.s}`,
      element: `${space.s} ${space.m}`,
      component: `${space.m} ${space.l}`,
      layout: `${space.l} ${space.xl}`,
    },
    stretch: {
      element_small: `${space.s} ${space.xs}`,
      element: `${space.m} ${space.s}`,
      component: `${space.l} ${space.m}`,
      layout: `${space.xl} ${space.l}`,
    },
    stack: {
      element_small: `${space.s} 0`,
      element: `${space.m} 0`,
      component: `${space.l} 0`,
      layout: `${space.xl} 0`,
      mobile_layout: `${space.m} 0`,
    },
    inline: {
      element_small: `0 ${space.s}`,
      element: `0 ${space.m}`,
      component: `0 ${space.l}`,
      layout: `0 ${space.xl}`,
      mobile_layout: `0 ${space.m}`,
    },
  },
  depth: {
    bottom: zIndex.level0,
    default: zIndex.level1,
    raised: zIndex.level2,
    raised_high: zIndex.level3,
    sticky: zIndex.level4,
    drawer: zIndex.level5,
    popup: zIndex.level6,
  },
};

const Provider = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
