import styled from "styled-components";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justify || "center"};
  align-items: ${props => props.align || "flex-start"};
  width: 100%;
`;

export default Column;
