import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { media } from "./media";
import fonts from "./fonts";

const GlobalStyles = createGlobalStyle`
  ${reset}
  ${fonts}

  html {
    box-sizing: border-box;
  }
  body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    background: #fbfbf9;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.125rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;

    ${media.tablet`
      font-size: 1.25rem;
    `}
    ${media.desktop`
      font-size: 1.375rem;
    `}
    ${media.giant`
      font-size: 1.5rem;
    `}
  }

  h1 {
    font-family: 'Trueno', 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.2;
    color: #494949;
    margin-bottom: 1rem;

    ${media.bigPhone`
      font-size: 2.25rem;
      margin-bottom: 1.5rem;
    `}
    ${media.tablet`
      font-size: 2.5rem;
      margin-bottom: 2rem;
    `}
    ${media.desktop`
      font-size: 3rem;
      margin-bottom: 2.5rem;
    `}
    ${media.giant`
    font-size: 3.125rem;
    margin-bottom: 3rem;
    `}
  }

  h2 {
    font-family: 'Trueno', 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1.2;
    color: #494949;
    margin-bottom: 1rem;

    ${media.bigPhone`
      font-size: 1.875rem;
      margin-bottom: 1.5rem;
    `}
    ${media.tablet`
      font-size: 2rem;
      margin-bottom: 2rem;
    `}
    ${media.desktop`
      font-size: 2.25rem;
      margin-bottom: 2.5rem;
    `}
    ${media.giant`
      font-size: 2.5rem;
      margin-bottom: 3rem;
    `}
  }

  h3 {
    font-family: 'Trueno', 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 1.2;
    color: #494949;
    margin-bottom: 1rem;

    ${media.bigPhone`
      font-size: 1.5rem;
    `}
    ${media.tablet`
      font-size: 1.625rem;
    `}
    ${media.desktop`
      font-size: 1.75rem;
    `}
    ${media.giant`
      font-size: 1.875rem;
    `}
  }

  a {
    color: #2CA698;
    font-size: 1.125rem;

    &:hover {
      color: #00675B;
      cursor: pointer;
    }

    ${media.tablet`
      font-size: 1.25rem;
    `}
    ${media.desktop`
      font-size: 1.375rem;
    `}
    ${media.giant`
      font-size: 1.5rem;
    `}
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

export default GlobalStyles;
