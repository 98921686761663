import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { media } from "../../theme/media";
import LogoInvert from "../../assets/logo.svg";
import Logo from "../../assets/logo-green.svg";

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 64px;
`;

const LogoLink = styled(Link)`
  display: block;
  width: 80px;
  height: auto;

  svg {
    width: inherit;
  }

  ${media.tablet`
    width: 160px;
  `};
`;

const Header = ({ className, invert, children }) => {
  return (
    <Content className={className}>
      <LogoLink to="/">{invert ? <LogoInvert /> : <Logo />}</LogoLink>
      {children}
    </Content>
  );
};

Header.defaultProps = {
  className: null,
  invert: false,
};

Header.propTypes = {
  /** For extending styles */
  className: PropTypes.string,
  invert: PropTypes.bool,
};

export default styled(React.memo(Header))``;
