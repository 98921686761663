import React, { useRef, useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo-hooks";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "../common/Button";

const SUBSCRIBE_MUTATION = gql`
  mutation newSubscription($values: SubscriptionInput!) {
    newSubscription(values: $values)
  }
`;

const Content = styled.div``;

const Input = styled.input`
  width: 100%;
  margin-bottom: 0.75rem;
  border: 1px solid #d5dcdb;
  border-radius: 2px;
  font-size: 1rem;
  padding: 1em;
`;

export const JoinForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${Button} {
    width: 100%;
  }
`;

const SubscribeForm = ({ className }) => {
  const inputRef = useRef();
  const [finished, setFinished] = useState(false);
  const [subscribe] = useMutation(SUBSCRIBE_MUTATION, {
    update: (proxy, mutationResult) => {
      setFinished(true);
    },
  });

  const submit = () =>
    subscribe({ variables: { values: { email: inputRef.current.value } } });

  return (
    <Content className={className}>
      {finished ? (
        <p>
          Thank you! Well keep you updated. <br />
          If you want to help us out feel free to share this site to your
          friends.
        </p>
      ) : (
        <JoinForm>
          <Input ref={inputRef} placeholder="email address" />
          <Button type="button" onClick={submit} primary>
            Join the journey
          </Button>
        </JoinForm>
      )}
    </Content>
  );
};

SubscribeForm.defaultProps = {
  className: null,
};

SubscribeForm.propTypes = {
  /** For extending styles */
  className: PropTypes.string,
};

export default styled(React.memo(SubscribeForm))``;
