import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Button from "../common/Button";

const Content = styled.div`
  ${Button} {
    margin-top: 1.5rem;
  }
`;

const Item = styled.li`
  display: flex;
  min-height: 48px;
  background: ${({ theme }) => theme.color.background.white};

  & + & {
    margin-top: 0.5rem;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  padding: 0.5rem 0;
  transition: all 0.3s;
  text-decoration: none;
  line-height: 1.6;

  &:hover {
    text-decoration: underline;
  }
`;

const NoData = styled.p`
  color: ${({ theme }) => theme.color.text_muted.on_light} !important;
  padding: 0.5rem 0;
`;

const List = styled.ul`
  columns: ${props => props.columns || 1};
`;

const NewsSiteList = ({
  className,
  newsSites,
  columns = 1,
  emptyText = "No shared background sites",
}) => {
  const [showAll, setShowAll] = useState(false);
  const list = newsSites.slice(0, showAll ? newsSites.length - 1 : 9);
  return (
    <Content className={className}>
      {newsSites.length > 0 ? (
        <List columns={columns}>
          {list.map(newsSite => (
            <Item key={newsSite.uuid}>
              <StyledLink to={`/${newsSite.slug}`}>{newsSite.name}</StyledLink>
            </Item>
          ))}
        </List>
      ) : (
        <NoData>{emptyText}</NoData>
      )}
      {newsSites.length > 15 && (
        <Button type="button" onClick={() => setShowAll(!showAll)}>
          {showAll ? "Show less" : `Show all ${newsSites.length}`}
        </Button>
      )}
    </Content>
  );
};

export default styled(React.memo(NewsSiteList))``;
