import React from "react";
import styled, { css } from "styled-components";
import { media } from "../../theme/media";
import PropTypes from "prop-types";

const Content = styled.button`
  padding: 1rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.color.border.primary};
  border-radius: 999px;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8125rem;
  line-height: 1;
  color: ${({ theme }) => theme.color.text_primary.on_light};
  outline: 0;
  background: transparent;

  ${media.tablet`
    font-size: 0.875rem;
  `}
  ${media.desktop`
    font-size: 1rem;
  `}

  &:disabled {
    color: ${({ theme }) => theme.color.text_muted.on_dark};
  }

  ${props =>
    props.invert &&
    css`
      color: ${({ theme }) => theme.color.text.on_dark};
      border-color: ${({ theme }) => theme.color.border.neutral};

      ${props.active &&
        css`
          border-color: ${({ theme }) => theme.color.border.primary};
          color: ${({ theme }) => theme.color.text_primary.on_light};
        `}
    `}

  ${props =>
    props.primary &&
    css`
      color: ${({ theme }) => theme.color.text.on_primary};
      background: ${({ theme }) => theme.color.background.primary};
    `}
`;

const Button = ({ className, invert, children, primary, active, ...rest }) => {
  return (
    <Content
      className={className}
      primary={primary}
      invert={invert}
      active={active}
      {...rest}
    >
      {children}
    </Content>
  );
};

Button.defaultProps = {
  className: null,
  invert: false,
  primary: false,
};

Button.propTypes = {
  /** For extending styles */
  className: PropTypes.string,
  invert: PropTypes.bool,
  primary: PropTypes.bool,
};

export default styled(React.memo(Button))``;
