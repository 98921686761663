import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { media } from "../../theme/media";
import DiscordLogo from "../../assets/discord-logo.svg";
import SubscribeForm from "../subscribe/SubscribeForm";
import Column from "./Column";

const Content = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${Column} {
    max-width: 560px;

    ${SubscribeForm} {
      width: 100%;
    }
  }
`;

const DiscordLogoStyled = styled(DiscordLogo)`
  width: 240px;
`;

const ContactWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  ${media.tablet`justify-content: flex-start;`}
`;

const FooterLinks = styled.a`
  & + & {
    margin-left: 1rem;
  }
`;

const Footer = ({ className }) => {
  return (
    <Content className={className}>
      <Column align="center">
        <h1>Join our community</h1>
        <p>Be the first to know about our progress.</p>
        <SubscribeForm />
      </Column>
      <ContactWrapper>
        <FooterLinks href="mailto://shoulditrustmedia@protonmail.com">
          Contact
        </FooterLinks>
        <FooterLinks href="https://discord.gg/NdZPNQp">
          <DiscordLogoStyled />
        </FooterLinks>
      </ContactWrapper>
    </Content>
  );
};

Footer.defaultProps = {
  className: null,
};

Footer.propTypes = {
  /** For extending styles */
  className: PropTypes.string,
};

export default styled(React.memo(Footer))``;
