import { css } from "styled-components";

const fontFace = (name, filename, weight = "normal", style = "normal") => css`
    @font-face {
    font-family: "${name}";
      src: url('${require(`./fonts/${filename}.woff2`)}') format("woff2"),
        url('${require(`./fonts/${filename}.woff`)}') format("woff");
      font-weight: ${weight};
      font-style: ${style};
    }
  `;

export default css`
  ${fontFace("Trueno", "TruenoRg")};
  ${fontFace("Trueno Light", "TruenoLt")};
  ${fontFace("Trueno UltraLight", "TruenoUltLt")};
  ${fontFace("Trueno Bold", "TruenoBd")};
  ${fontFace("Trueno SemiBold", "TruenoSBd")};
  ${fontFace("Trueno ExtraBold", "TruenoExBd")};
  ${fontFace("Trueno Black", "TruenoBlk")};
  ${fontFace("Trueno UltraBlack", "TruenoUltBlk")};
`;
