import React from "react";
import styled from "styled-components";
import Select from "react-select";
import { navigate } from "gatsby";

import { media } from "../../theme/media";

const StyledSelect = styled(Select)`
  font-size: 1.125rem;
  line-height: 1.5;

  ${media.desktop`
    font-size: 1.25rem;
  `}
`;

const SelectNewsSite = ({ className, newsSites, value }) => {
  const options = newsSites.map(site => ({
    label: site.name,
    value: site.slug,
  }));
  const selected = value && options.find(s => s.value === value);
  return (
    <StyledSelect
      className={className}
      placeholder="Select media..."
      defaultValue={selected}
      options={options}
      onChange={({ value: slug }) => navigate(`/${slug}`)}
    />
  );
};

export default styled(React.memo(SelectNewsSite))``;
